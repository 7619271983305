<template>
    <update-form
        type="humanResourceGeneral"
        :survey-parts="$parent.surveyParts"
        :fields="fields"
    />
</template>

<script>
    import UpdateForm from "@/components/data/surveryPart/UpdateForm";

    export default {
        components: {
            UpdateForm
        },
        computed: {
            fields: function() {
                return [
                    {
                        key: 'absenteeism',
                        label: this.$t('Absenteeism'),
                        type: 'number',
                        unit: this.$t('Working days in the last 12 months'),
                        tooltip: this.$t('Total of all sick leave days of all employees in the last 12 months')
                    },
                    {
                        key: 'numberOfIncapacityForWorkReports',
                        label: this.$t('Number of reports of incapacity for work'),
                        type: 'number',
                        unit: this.$t('Number of reports in the last 12 months'),
                    },
                    {
                        key: 'daysOfIncapacity',
                        label: this.$t('Days of reports of incapacity for work'),
                        type: 'number',
                        unit: this.$t('Number of days with a report of incapacity for work'),
                        tooltip: this.$t('Working days that all employees with an report of incapacity for work have missed in the last 12 months.'),
                    },
                    {
                        key: 'casesOfLongTimeIllness',
                        label: this.$t('Cases of illness > 42 days'),
                        type: 'number',
                        unit: this.$t('Number in the last 12 months'),
                    },
                    {
                        key: 'initiatedBEMProcedures',
                        label: this.$t('Initiated OIM procedures'),
                        type: 'number',
                        unit: this.$t('Number in the last 12 months'),
                    },
                    {
                        key: 'completedBEMProcedures',
                        label: this.$t('Completed OIM procedures'),
                        type: 'number',
                        unit: this.$t('Number in the last 12 months'),
                    },
                    {
                        key: 'numberOfHires',
                        label: this.$t('Additions of new employees'),
                        type: 'number',
                        unit: this.$t('Number in the last 12 months'),
                    },
                    {
                        key: 'numberOfExists',
                        label: this.$t('Departures employees'),
                        type: 'number',
                        unit: this.$t('Number of all persons who have left the company in the last 12 months'),
                    },
                    {
                        key: 'numberOfExistsForcedByEmployee',
                        label: this.$t('Termination on the part of the employee'),
                        type: 'number',
                        unit: this.$t('Number of terminations in the last 12 months'),
                    },
                    {
                        key: 'earlyRetirements',
                        label: this.$t('Early retirements'),
                        type: 'number',
                        unit: this.$t('Number in the last 12 months'),
                    },
                    {
                        key: 'nearMisses',
                        label: this.$t('Near misses'),
                        type: 'number',
                        unit: this.$t('Number in the last 12 months'),
                    },
                    {
                        key: 'accidentsAtWork',
                        label: this.$t('Occupational accidents'),
                        type: 'number',
                        unit: this.$t('Number in the last 12 months'),
                    },
                    {
                        key: 'reportableAccidentsAtWork',
                        label: this.$t('Reportable occupational accidents'),
                        type: 'number',
                        unit: this.$t('Number in the last 12 months'),
                    },
                    {
                        key: 'overtime',
                        label: this.$t('Overtime'),
                        type: 'number',
                        unit: this.$t('Number of hours in the last 12 months'),
                        tooltip: this.$t('All overtime hours actually worked in the last 12 months including those that were compensated by special measures (e.g. remuneration, compensatory time, elimination, ...).'),
                    },
                ];
            }
        }
    }
</script>
